export const slide = {
    initial: { x: -400 },
    animate: { x:0}
};
export const opacity= {
    initial: { opacity: 0 },
    animate: { opacity:1}
};
export const duration={
    fast: 25,
    medium: .45,
    slow: .75,
    slowest: 1
}