const CompanyInfo = () => {
	return (
		<div className="company-info">
			<p style={{fontStyle:'italic'}}> "I do what I do, because I do what I do.
				They all say that the much I do isn't worth doing.
				But I still do what I do because, I do what I do."
			</p>
			<p className='quote'>
				- Diligent loser
			</p>			
		</div>
	);
};

export default CompanyInfo;
