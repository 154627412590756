export const photos = [
  {
    src: "https://source.unsplash.com/iAL4wp9TMxQ/",
    width: 5,
    height: 3
  },
  {
    src: "https://source.unsplash.com/DqBcC718cEA/",
    width: 3,
    height: 3
  },
  {
    src: 'https://source.unsplash.com/9z6ldWjo7eY/',
    width: 5,
    height: 3
  },
  {
    src: "https://source.unsplash.com/NFDwfbiFeKc/",
    width: 4,
    height: 3
  },

  {
    src: "https://source.unsplash.com/o_M6J5BYloc/",
    width: 4,
    height: 3
  },
  // {
  //   src: "https://source.unsplash.com/abwJ_2g9t6k/600x799",
  //   width: 2.5,
  //   height: 3
  // },
  {
    src: 'https://source.unsplash.com/6-afi_aUuDU/',
    width: 4,
    height: 3
  },
  {
    src: "https://source.unsplash.com/XfnSXmK2eS0/",
    width: 6,
    height: 3
  },
  {
    src: "https://source.unsplash.com/W6sJqcKlHYk/",
    width: 5,
    height: 3
  },
  {
    src: "https://source.unsplash.com/1c7d_pMlVVw/",
    width: 3,
    height: 3
  },
  {
    src: "https://source.unsplash.com/pVVoNe2J-w8/800x599",
    width: 5,
    height: 3
  },
  {
    src: "https://source.unsplash.com/d84v-ApM7os/",
    width: 3,
    height: 3
  },
  {
    src: "https://source.unsplash.com/ltM8L-9lTAs/800x599",
    width: 5,
    height: 3
  },
  {
    src: "https://source.unsplash.com/kfM1ER4LKuo/",
    width: 5,
    height: 3
  },
  // {
  //   src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
  //   width: 3,
  //   height: 4
  // },
  // {
  //   src: "https://source.unsplash.com/PpOHJezOalU/800x599",
  //   width: 4,
  //   height: 3
  // },


];
